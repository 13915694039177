import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript"

import Layout from "../components/layout";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
// import IconCheck from "../images/icons/interface/check.svg";

const GraphicDesignerPage = ({ data, location: { pathname } }) => (
  <Layout headerBgClass="bg-primary-alt" hideFooter footerBgClass="bg-white" meta={{ seo: data.datoCmsPage.seo, path: pathname }}>
    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">Graphic Designer<br/>FT/PT/Freelance 100% Remote</h1>
              <div className="lead">
                <p>If you live and breathe Creativity & Graphic Design, we need to talk.</p>
                <p>We’re looking for a flexible and versatile Graphic Designer who will be responsible for creating online presentation of our projects, infographics and related visual design work.
                </p>
              </div>

              <p className="lead mb-2"><strong>Responsibilities:</strong></p>
              <ul className="lead mb-5">
                <li>Creation of Infographics</li>
                <li>Online presentation for Behance, Dribbble, LinkedIn</li>
                <li>Creation of PDF documents & templates</li>
              </ul>


              <p className="lead mb-2"><strong>Requirements:</strong></p>
              <ul className="lead mb-5">
                <li>2+ year of UX , UI or Product design hands-on experience</li>
                <li>Fluent+ English</li>
                <li>Working during European time (CET)</li>
                <li>Proficiency in Adobe CC and knowledge of Sketch / Figma (to export assets)</li>
                <li>Communicating about any questions/problems/ideas/suggestions, asking for feedback and sharing feedback easily.</li>
                <li>Being in charge of tasks, to know and tell others about ability or disability to fit the time limit</li>
              </ul>

              <p className="lead mb-2"><strong>Benefits:</strong></p>
              <ul className="lead mb-5">
                <li>100% Remote</li>
                <li>No micromanagement</li>
                <li>Balanced workload</li>
              </ul>

            </div>

            <div className="col-12 col-md-8">
              <div className="card card--common card-body card--no-hover">
                <div id="activecampaign-form">
                  <div id="activecampaign-form__wrapper">
                    <div className=" _form_21"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>

    {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=21")}
  </Layout>
);

export default GraphicDesignerPage;

export const query = graphql`
  query graphicDesignerPage {
    datoCmsPage(slug: { eq: "graphic-designer" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
